.impress-margin {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-top {
  margin-top: 80px;
}

.margin-rund {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.centering {
  max-width: 700px;
  margin: 0 auto;
}

.textcenter {
  text-align: center;
}

body {
  background-color: E40017;
}

.lieferbit {
  font-size: 3.5em;
  font-weight: 800;
}

.titel {
  font-size: 2.9em;
}

.bullets {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.gross {
  font-size: 1.8em;
}

.bisschen {
  font-size: 1.4em;
}

.texteins {
  font-size: 0.7em;
}

.unterstrichen {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.start-img {
  max-height: 300px;
}

.fweight {
  font-weight: 500;
}

.fweightgross {
  font-weight: 800;
}

.textweiss {
  color: white;
}

.italic {
  font-style: italic;
}

.margin-agb {
  margin-left: 5px;
  margin-right: 5px;
}

button {
  margin-top: 10px;
  padding: 8px 12px;
  background: #a20a0a;
  border: 0;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  border-radius: 7px !important;
  color: white;
}

li {
  margin: 0 0 7px 0;
}

.margin-kleintop {
  margin-top: 8px;
}
